import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import i18next from "i18next";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import translation from "zod-i18n-map/locales/ja/zod.json";

if (process.env.NODE_ENV !== "development") {
	Sentry.init({
		dsn: "https://9960167dc8ca265aaf0aadbd244e3ce6@o4505985005322240.ingest.us.sentry.io/4507899691270144",
		tracesSampleRate: 0.01,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,

		integrations: [
			Sentry.browserTracingIntegration({
				useEffect,
				useLocation,
				useMatches,
			}),
			Sentry.replayIntegration(),
		],
	});
}

i18next.init({
	lng: "ja",
	resources: {
		ja: { zod: translation },
	},
});
z.setErrorMap(zodI18nMap);

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<RemixBrowser />
		</StrictMode>,
	);
});
